.imgDiv {
    background-color: black;
    background-image: url(fantasyClock.jpg);
    background-size: cover;
    margin: 0 auto 20px;
    padding: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    min-height: 460px;
  }

  .quoteDiv {
    padding: 0 20px 20px;
    font-size: 16px;
  }

  .buttonDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .attrDiv {
    margin: 100px 20px 4px;
    font-size: 12px;
  }

  button {
    border: none;
    border-radius: 6px;
    height: 20px;
    background-color: #c2a27a;
    color: white;
    font-size: 18px;
    padding: 20px 24px;
    cursor: pointer;
    text-align: center;
    display: inline-flex;
    align-items: center;
  }

  @media (min-width: 400px) {
    .quoteDiv {
      padding: 0 35px 20px;
    }

    .attrDiv {
      margin: 100px 20px 8px;
    }
  }

  @media (min-width: 699px) {
    .quoteDiv {
      padding: 0 60px 20px;
      font-size: 24px;
    }
  }